<template>
    <v-container>
        <v-layout row wrap align-center justify-center>
            <template v-if="!consultado">
                <v-flex xs12>
                    <v-img :src="require('../../assets/rpa.png')" contain height="150"></v-img>
                </v-flex>
                <v-flex xs12 class="text-center" >
                    <v-progress-circular :size="50" color="orange" indeterminate></v-progress-circular>
                    <span class="ml-5">Verificando datos de aprobacion de documento...</span>
                </v-flex>
            </template>
            <v-flex xl8 lg8 md6 xs12 class="text-left" v-else>
                <v-card class="my-0 py-0">
                    <v-card-text>
                        <div>
                            <v-img :src="require('@/assets/btn_ciudadania_digital_v2.png')" contain aspect-ratio="6"></v-img>
                        </div>
                        <div class="text-h5 text-center font-weight-black">Documento Aprobado en Ciudadanía Digital</div>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title><b>UUID de la Solicitud: </b>
                                   {{query.requestUuid}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title><b>Estado: </b>
                                    <v-chip :color="query.estado?'success':'error'">{{query.estado?'APROBADO':'RECHAZADO'}}</v-chip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title><b>Mensaje Servicio Aprobación: </b> {{query.mensaje}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title><b>UUID de la blockchain: </b>{{query.uuidBlockchain}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title><b>Codigo de la transaccion en la blockchain: </b> {{query.transactionCode}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <a :href="query.linkVerificacionUnico" target="_blank"><v-icon color="primary"> mdi-link </v-icon> Link de verificacion del documento</a>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn rounded color="default" text to="/rpa"><v-icon> mdi-home </v-icon> Volver al inicio</v-btn>
                        <v-btn rounded color="primary" @click="getDatosRegistro()"><v-icon> mdi-arrow-right-thick </v-icon> continuar</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import mixinRegistro from '@/mixins/registro'
export default {
    mixins:[mixinRegistro],
    data(){
        return{
            query:this.$route.query,
            consultado:false
        }
    },
    created(){
        if(this.query.requestUuid == null){
            this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error Ruta",
                    text: "El parametro Uuid es requerido"
                })
            this.$router.push('/rpa')
        }else{
            this.vertificarAprobacion()
        }
    },
    methods:{
        vertificarAprobacion(){
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/solicitud-aprobacion-documento/${this.query.requestUuid}`)
            .then(response=>{
                this.consultado=true
                this.query.urlSolicitudIncial=response.data.data.urlSolicitudCliente
                this.query.descripcion=response.data.data.descripcion
                this.$storage.set("continue", response.data.data.urlSolicitudCliente);
            }).catch(error=>{
                this.consultado=true
                console.log(error.data)
            })
        }
    }
}
</script>